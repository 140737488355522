import React from "react"
import Layout from "../components/Layout"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import { ReferencesTable } from "../components/ReferencesTable"
import { ReferencesTableEn } from "../components/ReferencesTableEn"
import { useLanguage } from "../contexts/LanguageContext"
const Concrete = ({
  data: {
    file: {
      childImageSharp: { fluid },
    },
  },
}) => {
  const { selectedLanguage } = useLanguage() // Use the useLanguage hook
  return (
    <Layout>
      <div className="img-wrapper img-wrapper-about">
        <Image Tag="div" fluid={fluid} className=" bcg bcg-about  " />
        {selectedLanguage === "EN" ? (
          <h2 className="about-header visibility-filter contact-header">
            Our References
          </h2>
        ) : (
          <h2 className="about-header visibility-filter contact-header">
            Naše Reference
          </h2>
        )}
        {selectedLanguage === "EN" ? (
          <h2 className="about-header mobile-visible">References</h2>
        ) : (
          <h2 className="about-header mobile-visible">Reference</h2>
        )}
      </div>
      {selectedLanguage === "EN" ? (
        <div className="about-content">
          <div className="">
            <ReferencesTableEn />
          </div>
        </div>
      ) : (
        <div className="about-content">
          <div className="">
            <ReferencesTable />
          </div>
        </div>
      )}
      <div className="about-content">
        <div className="">
          <ReferencesTable />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    file(relativePath: { eq: "EM207907.jpg" }) {
      childImageSharp {
        fluid(
          sizes: "(max-width: 300px) calc(100vw - 25px), (max-width: 600px) calc(100vw - 15px), 1300px"
        ) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
export default Concrete
